<template>
  <v-card>
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>{{ $t("tasks and orders") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            @click="getItems"
            :disabled="loading"
            :loading="loading"
            icon
          >
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("refresh tasks") }}</span>
      </v-tooltip>
      <wiki slug="tasks-overview" />
    </v-toolbar>

    <v-card-subtitle>
      <v-row class="mb-0 pb-0">
        <v-col>
          <v-select
            :items="statuses"
            :label="$t('status')"
            v-model="status"
            item-text="text"
            item-value="value"
            dense
            :clearable="!isMobile"
            @change="getItems"
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            :items="dates"
            :label="$t('created')"
            v-model="created"
            item-text="text"
            item-value="value"
            dense
            :clearable="!isMobile"
            @change="getItems"
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            :items="dates"
            :label="$t('updated')"
            v-model="updated"
            item-text="text"
            item-value="value"
            dense
            :clearable="!isMobile"
            @change="getItems"
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            :items="types"
            :label="$t('type')"
            v-model="type"
            item-text="text"
            item-value="value"
            dense
            :clearable="!isMobile"
            @change="getItems"
          ></v-select>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col class="mt-n6 pt-n6">
          <v-text-field
            v-model="subject"
            append-outer-icon="mdi-magnify"
            clear-icon="mdi-close-circle"
            :clearable="!isMobile"
            v-bind:label="$t('subject')"
            single-line
            hide-details
            @click:append-outer="getItems"
            @click:clear="clearSearch"
            @keyup.enter.native="getItems"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-subtitle>
    <v-card-text>
      <data-table-extended
        :headers="headers"
        :items="tasks"
        :options.sync="options"
        :loading="loading"
        :mobile-breakpoint="mbreakpoint"
        :expanded.sync="expanded"
        show-expand
        single-expand
        sort-by="row"
        :hide-default-footer="tasks.length <= 15"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [15, 50],
        }"
        local-storage-name="taskList"
        open-expand-by-dbl-click-row
      >
        <template v-slot:item.action="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                @click="
                  $router.push({
                    name: 'task-details',
                    params: { taskId: item.id },
                  })
                "
                text
                small
                icon
              >
                <v-icon>mdi-eye-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("show ticket") }}</span>
          </v-tooltip>
        </template>

        <template v-slot:item.status_code="{ item }">
          <v-chip
            :color="getStatusColor(item.status_code)"
            class="white--text"
            >{{ $t(item.status_code) }}</v-chip
          >
        </template>

        <template v-slot:item.created="{ item }">
          <div class="monospace text-no-wrap">
            {{ formatDateTime(item.created) }}
          </div>
        </template>

        <template v-slot:item.updated="{ item }">
          <div class="monospace text-no-wrap">
            {{ item.updated ? formatDateTime(item.updated) : "-" }}
          </div>
        </template>

        <template v-slot:item.canceled="{ item }">
          <div class="monospace text-no-wrap">
            {{ item.canceled ? formatDateTime(item.canceled) : "-" }}
          </div>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <task-short-details
              @workerCancled="getItems()"
              :task-id="item.id"
            />
          </td>
        </template>
      </data-table-extended>
    </v-card-text>
  </v-card>
</template>

<script>
import DataTableExtended from "@/components/basics/DataTableExtended.vue";
import formatDateTime from "@/utils/mixins/formatDateTime";
import TaskShortDetails from "@/components/orders/TaskShortDetails";
import isMobile from "@/utils/mixins/isMobile";
import Wiki from "@/components/basics/Wiki";

let dt = new Date();
dt.setMonth(dt.getMonth() - 6);

export default {
  name: "Tasks",
  mixins: [formatDateTime, isMobile],
  components: {
    DataTableExtended,
    TaskShortDetails,
    Wiki,
  },
  props: {
    selectedTaskId: {
      type: [String, Number],
      required: false,
      default: null,
    },
  },
  data: () => ({
    tasks: [],
    subject: null,
    status: null,
    created: null,
    updated: dt.toISOString().slice(0, 10),
    type: null,
    loading: false,
    options: {},
    expanded: [],
  }),
  watch: {
    options: {
      handler() {
        this.getItems();
      },
      deep: true,
    },
  },
  computed: {
    dates() {
      return [
        {
          text: this.$i18n.t("since 1 day"),
          value: this.dateToString(this.sinceDays(1)),
        },
        {
          text: this.$i18n.t("since 1 week"),
          value: this.dateToString(this.sinceDays(7)),
        },
        {
          text: this.$i18n.t("since 1 month"),
          value: this.dateToString(this.sinceMonths(1)),
        },
        {
          text: this.$i18n.t("since 3 months"),
          value: this.dateToString(this.sinceMonths(3)),
        },
        {
          text: this.$i18n.t("since 6 months"),
          value: this.dateToString(this.sinceMonths(6)),
        },
        {
          text: this.$i18n.t("since 1 year"),
          value: this.dateToString(this.sinceMonths(12)),
        },
      ];
    },
    statuses() {
      return [
        { text: this.$i18n.t("running"), value: "running" },
        { text: this.$i18n.t("waiting"), value: "waiting" },
        { text: this.$i18n.t("finished"), value: "finished" },
        { text: this.$i18n.t("failed"), value: "failed" },
        { text: this.$i18n.t("canceled"), value: "canceled" },
      ];
    },
    types() {
      return [
        {
          text: this.$i18n.t("address change"),
          value: "workerticketaddresschange",
        },
        {
          text: this.$i18n.t("BBCS ticket via iWay"),
          value: "workerticketbbcsiway",
        },
        {
          text: this.$i18n.t("BBCS order"),
          value: "workerticketbbcsorder",
        },
        {
          text: this.$i18n.t("BBCS ticket"),
          value: "workerticketbbcsticket",
        },
        {
          text: this.$i18n.t("hardware shop order"),
          value: "workertickethardwareshoporder",
        },
        {
          text: this.$i18n.t("Metronet order"),
          value: "workerticketmetronetorder",
        },
        {
          text: this.$i18n.t("product lock"),
          value: "workerticketproductblock",
        },
        {
          text: this.$i18n.t("product unlock"),
          value: "workerticketproductunblock",
        },
        {
          text: this.$i18n.t("product termination"),
          value: "workerticketproducttermination",
        },
        {
          text: this.$i18n.t("profile change"),
          value: "workerticketprofilechange",
        },
        {
          text: this.$i18n.t("product support ticket"),
          value: "workerticketsupport",
        },
        {
          text: this.$i18n.t("customer support ticket"),
          value: "workerticketgenericsupport",
        },
        {
          text: this.$i18n.t("TV create"),
          value: "workerticketzumatvcreate",
        },
        {
          text: this.$i18n.t("TV update"),
          value: "workerticketzumatvupdate",
        },
      ];
    },
    headers() {
      return [
        {
          text: this.$i18n.t("status"),
          value: "status_code",
        },
        {
          text: this.$i18n.t("subject"),
          value: "subject",
        },
        {
          text: this.$i18n.t("created"),
          align: "right",
          value: "created",
          hidden: true,
        },
        {
          text: this.$i18n.t("updated"),
          align: "right",
          value: "updated",
        },
        {
          text: this.$i18n.t("canceled"),
          align: "right",
          value: "canceled",
          hidden: true,
        },
        {
          text: this.$i18n.t("type"),
          value: "worker_name",
        },
        { text: "", value: "data-table-expand" },
        { text: "", value: "action", sortable: false },
      ];
    },
  },
  watch: {
    "$store.state.session"() {
      this.getItems();
    },
    created() {
      this.getItems();
    },
    updated() {
      this.getItems();
    },
    status() {
      this.getItems();
    },
    type() {
      this.getItems();
    },
  },
  methods: {
    dateToString(dt) {
      return dt.toISOString().slice(0, 10);
    },
    sinceDays(n) {
      var dt = new Date();
      dt.setDate(dt.getDate() - n);
      return dt;
    },
    sinceMonths(n) {
      var dt = new Date();
      dt.setMonth(dt.getMonth() - n);
      return dt;
    },
    getStatusColor(status_code) {
      return (
        "worker" + status_code.charAt(0).toUpperCase() + status_code.slice(1)
      );
    },
    clearSearch: function () {
      this.subject = null;
      this.getItems();
    },
    getItems: function () {
      var that = this;
      this.loading = true;
      var params = {
        q: this.subject,
        created_after: this.$moment(this.created).toISOString(),
        updated_after: this.$moment(this.updated).toISOString(),
        status: this.status,
        worker_code: this.type,
      };
      this.$http
        .get("workers", { params: params })
        .then((response) => {
          that.tasks = response.data;
          // expand selected task (from router props param)
          if (that.selectedTaskId != null && that.expanded.length === 0)
            that.tasks.forEach(function (task) {
              if (task.id == that.selectedTaskId) that.expanded = [task];
            });
        })
        .catch((err) => {
          if (err.invalid()) {
            that.$store.commit("setSystemError", {
              msg: err.message,
              title: err.title,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
  mounted() {
    this.getItems();
  },
};
</script>

<i18n>
{
  "en": {
    "tasks and orders": "Tasks and orders",
    "running tasks": "Running tasks",
    "closed tasks": "Closed tasks",
    "status": "Status",
    "type": "Type",
    "created": "Created",
    "updated": "Updated",
    "subject": "Subject",
    "running": "Running",
    "waiting": "Waiting",
    "finished": "Finished",
    "canceled": "Canceled",
    "failed": "Failed",
    "since 1 day": "Since today",
    "since 1 week": "Since this week",
    "since 1 month": "Since this month",
    "since 3 months": "Since this year",
    "since 6 months": "Since this year",
    "since 1 year": "Since last year",
    "address change": "Address change",
    "BBCS order": "BBCS order",
    "BBCS ticket": "BBCS ticket",
    "BBCS ticket via iWay": "BBCS ticket via iWay",
    "hardware shop order": "Hardware shop order",
    "Metronet order": "Metronet order",
    "product lock": "Product lock",
    "product unlock": "Product unlock",
    "product termination": "Product termination",
    "profile change": "Profile change",
    "product support ticket": "Product support ticket",
    "customer support ticket": "Customer support ticket",
    "TV create": "TV order",
    "TV update": "TV change",
    "all": "All",
    "show ticket": "Show ticket",
    "refresh tasks": "Refresh tasks"
  },
  "de": {
    "tasks and orders": "Aufträge und Bestellungen",
    "running tasks": "laufende Aufgaben",
    "closed tasks": "abgeschlossene Aufgaben",
    "status": "Status",
    "type": "Typ",
    "created": "angelegt",
    "updated": "aktualisiert",
    "subject": "Betreff",
    "running": "läuft",
    "waiting": "wartet",
    "finished": "beendet",
    "canceled": "abgebrochen",
    "failed": "fehlgeschlagen",
    "since 1 day": "seit 1 Tag",
    "since 1 week": "seit 1 Woche",
    "since 1 month": "seit 1 Monat",
    "since 3 months": "seit 3 Monaten",
    "since 6 months": "seit 6 Monaten",
    "since 1 year": "seit einem Jahr",
    "address change": "Adressänderung",
    "BBCS order": "BBCS Bestellung",
    "BBCS ticket": "BBCS Ticket",
    "BBCS ticket via iWay": "BBCS Ticket via iWay",
    "hardware shop order": "Hardware Shop Bestellung",
    "Metronet order": "Metronet Bestellung",
    "product lock": "Produkt Sperrung",
    "product unlock": "Produkt Entsperrung",
    "product termination": "Produkt Kündigung",
    "profile change": "Profiländerung",
    "product support ticket": "Produkt Support Ticket",
    "customer support ticket": "Kunden Support Ticket",
    "TV create": "TV Bestellung",
    "TV update": "TV Änderung",
    "all": "alle",
    "show ticket": "zeige Ticket",
    "refresh tasks": "Aufträge aktualisieren"
  }
}
</i18n>
